import DesignComponent from "./designComponent";

const hr = new DesignComponent({
  structure: {
    tag: "hr",
    data: {
      attrs: {},
      style: {
        "background-color": "#d0d0d0",
        width: "100%"
      },
      class: []
    }
  }
});

export default new DesignComponent({
  info: {
    title: "Separator",
    img: require(`../../../assets/img/icons/separator.svg`)
  },
  structure: {
    tag: "div",
    data: {
      props: {
        designId: "separator",
        configurable: "Separator"
      },
      attrs: {
        draggable: true
      },
      style: {
        display: "flex",
        "align-items": "center",
        margin: "0 auto",
        "background-color": "white",
        "flex-shrink": 0
      },
      class: []
    },
    children: [hr.structure]
  }
});
