import Router from "vue-router";

// Containers
const MainLayout = () => import("@/layout/main.vue");

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/todo-app",
      name: "Codementum",
      component: MainLayout,
      children: [
        {
          path: ":slug",
          name: "Workspace",
          component: () => import("@/views/workspace/workspaceContainer.vue")
        }
      ]
    }
  ]
});
