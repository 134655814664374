import DesignComponent from "./designComponent";
import { VNode } from "vue";

export default new DesignComponent({
  info: {
    title: "Heading",
    img: require(`../../../assets/img/icons/heading.svg`)
  },
  structure: {
    tag: "h3",
    data: {
      props: {
        designId: "heading",
        configurable: "Heading"
      },
      attrs: {
        draggable: true
      },
      domProps: {
        innerHTML: "Head"
      },
      style: {
        "text-align": "center",
        "margin-top": "10px"
        // "font-size": "20px"
      },
      class: []
    }
  },
  settings: [
    {
      label: "Content",
      inputType: "text",
      get: (vnode: VNode) => vnode.data.domProps.innerHTML,
      set: (vnode: VNode, value) => (vnode.data.domProps.innerHTML = value)
    },
    {
      label: "Size",
      options: [
        {
          text: "h1",
          value: "h1"
        },
        {
          text: "h2",
          value: "h2"
        },
        {
          text: "h3",
          value: "h3"
        },
        {
          text: "h4",
          value: "h4"
        },
        {
          text: "h5",
          value: "h5"
        },
        {
          text: "h6",
          value: "h6"
        }
      ],
      inputType: "select",
      get: (vnode: VNode) => vnode.tag,
      set: (vnode: VNode, value) => (vnode.tag = value)
    }
  ]
});
