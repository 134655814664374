import { VNode } from "vue";

const findPathOfVNodeById = (el: VNode, id: string, path: any[] = []) => {
  const recursive = (path: any[], el: VNode, id: string) => {
    if (el?.data?.attrs?.id === id) return true;

    if (el?.children) {
      path.push("children");

      for (let index = 0; index < el.children.length; index++) {
        const child = el.children[index];
        const subPath = [index];
        const result = recursive(subPath, child, id);
        if (result) {
          path.push(...subPath);
          return true;
        }
      }
    }

    path.pop();
    return false;
  };
  recursive(path, el, id);
  if (path.length) return path;
};

export default findPathOfVNodeById;
