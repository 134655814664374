import DesignComponent from "./designComponent";

const i = new DesignComponent({
  structure: {
    tag: "i",
    data: {
      attrs: {},
      style: {
        "font-size": "64px",
        color: "rgb(136, 136, 136)",
        "vertical-align": "middle"
      },
      class: ["icon", "ion-ios-videocam"]
    }
  }
});

export default new DesignComponent({
  info: {
    disabled: true,
    title: "Video",
    img: require(`../../../assets/img/icons/video.svg`)
  },
  structure: {
    tag: "div",
    data: {
      props: {
        designId: "video",
        configurable: "Video"
      },
      attrs: {
        draggable: true
      },
      style: {
        "line-height": "170px",
        "background-color": "rgb(232, 235, 239)",
        "text-align": "center"
      },
      class: []
    },
    children: [i]
  }
});
