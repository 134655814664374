import { VNode } from "vue";
import DesignComponent from "./designComponent";

export default new DesignComponent({
  info: {
    title: "Input",
    img: require(`@/assets/img/icons/input.svg`)
  },
  structure: {
    tag: "ion-input",
    data: {
      props: {
        designId: "input",
        configurable: "Input"
      },
      attrs: {
        value: "",
        placeholder: "Input Text",
        draggable: true
      },
      domProps: {
        value: ""
      },
      style: {
        overflow: "hidden",
        "text-overflow": "ellipsis",
        "--background": "white",
        "border-bottom": "1px solid #eee",
        color: "black",
        "font-size": "14px",
        "text-align": "center",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        margin: "0 auto",
        flex: "0 0 auto"
      },
      class: []
    }
  },
  settings: [
    {
      label: "Placeholder",
      inputType: "text",
      get: (vnode: VNode) => vnode.data.attrs.placeholder,
      set: (vnode: VNode, value) => (vnode.data.attrs.placeholder = value)
    },
    {
      label: "Text align",
      inputType: "select",
      options: [
        {
          text: "Left",
          value: "left"
        },
        {
          text: "Center",
          value: "center"
        },
        {
          text: "Right",
          value: "right"
        }
      ],
      get: (vnode: VNode) => vnode.data.style["text-align"],
      set: (vnode: VNode, value) => (vnode.data.style["text-align"] = value)
    }
  ]
});
