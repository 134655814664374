import designComponents from "../../../types/design-elements/components";
import { default as Vue, VNode } from "vue";
import findVNodeById from "../../../types/shared/findVNodeById";
import findPathOfVNodeById from "../../../types/shared/findPathOfVNodeById";
import modifyAllIds from "../../../types/shared/modifyAllIds";
import { cloneDeep } from "lodash";
//var jmespath = require("jmespath");

interface IAction {
  commit: (...any: any[]) => any;
  dispatch: (...any: any[]) => any;
  getters: any;
  rootGetters: string[];
  rootState: any;
  state: IScreen;
}

interface IScreenPage {
  pageNumber: number;
  design: VNode;
  code: string;
}

interface IScreen {
  os: string;
  showingPage: number;
  pages: IScreenPage[];
  plugins: Map<string, any>;
  services: Map<string, any>;
  uniqueIdCounter: Map<string, number>;
}
import { omit as _omit } from "lodash";

export default {
  namespaced: false,
  state: {
    os: "iOS",
    showingPage: null,
    pages: [],
    plugins: {},
    services: {},
    uniqueIdCounter: new Map()
  },
  getters: {
    allDesignPages(state: IScreen) {
      return state.pages;
    },
    showingScreenPage(state: IScreen) {
      return state.showingPage;
    },
    screenPageLength(state) {
      return state.pages.length;
    },
    showingDesignPage(state: IScreen, getters) {
      const wantedPage = getters.showingScreenPage;
      const foundPage = state.pages.find(
        page => page.pageNumber === wantedPage
      );
      return foundPage?.design;
    },
    serviceList(state: IScreen) {
      return state.services;
    },
    pluginList(state: IScreen) {
      return state.plugins;
    },
    screenOs(state: IScreen) {
      return state.os;
    },
    isScreenOsIos(state: IScreen, getters) {
      return getters.screenOs === "iOS";
    },
    isScreenOsAndroid(state: IScreen, getters) {
      return getters.screenOs === "ANDROID";
    },
    appJson(state: IScreen) {
      const screen = document.querySelector(".view-phone-container");
      return {
        os: state.os,
        plugins: state.plugins,
        services: state.services,
        pages: state.pages,
        uniqueIdCounter: [...state.uniqueIdCounter],
        ShowingScreenPage: state.showingPage,
        screenSize: [screen.clientWidth, screen.clientHeight]
      };
    }
  },
  mutations: {
    setScreenOs(state: IScreen, os) {
      state.os = os;

      // @todo refactor this
      const ionicOsCode = os === "ANDROID" ? "md" : "ios";
      const ionicOsCodeRevert = os === "ANDROID" ? "ios" : "md";

      const nodesRevert = document.querySelectorAll(
        '[mode="' + ionicOsCodeRevert + '"]'
      );
      nodesRevert.forEach((node: any) => {
        node.setAttribute("mode", ionicOsCode);
        node.classList.value = node.classList.value.replaceAll(
          ionicOsCodeRevert,
          ionicOsCode
        );
      });
      /*
        const nodes = document.querySelectorAll('[mode="' + ionicOsCode + '"]');

        nodes.forEach((node: Element) => {
          node.classList.value = node.classList.value.replaceAll(
            ionicOsCodeRevert,
            ionicOsCode
          );
        });*/
    },
    changeShowingScreenPage(state: IScreen, n) {
      if (!n) return;

      state.showingPage = n;
    },
    addScreenPage(state: IScreen, n) {
      const PageVNode = cloneDeep(designComponents.Page.structure);
      modifyAllIds(PageVNode, state.uniqueIdCounter, "");
      state.pages.push({
        pageNumber: n,
        design: PageVNode,
        code: ""
      });
    },
    removeScreenPage(state: IScreen, n) {
      const index = state.pages.findIndex(p => p.pageNumber === n);
      if (index !== -1) Vue.delete(state.pages, index);
    },
    setPageCode(state: IScreen, { pageNumber, code }) {
      const page = state.pages.find(page => page.pageNumber === pageNumber);
      page.code = code;
    },
    setUniqueIdCounter(state, uniqueIdCounter) {
      state.uniqueIdCounter = new Map(uniqueIdCounter);
    },
    setPages(state: IScreen, pages) {
      state.pages = pages;
    },
    setServices(state: IScreen, services) {
      state.services = {
        ...services
      };
    },
    addService(state: IScreen, { key, service }) {
      if (state.services[key] === undefined)
        Vue.set(state.services, key, service);
    },
    setPlugins(state: IScreen, plugins) {
      state.plugins = {
        ...plugins
      };
    },
    addPlugin(state: IScreen, { key, plugin }) {
      if (state.plugins[key] === undefined) Vue.set(state.plugins, key, plugin);
    },
    deleteService(state: IScreen, key) {
      if (state.services[key] !== undefined) Vue.delete(state.services, key);
    },
    deletePlugin(state: IScreen, key) {
      if (state.plugins[key] !== undefined) Vue.delete(state.plugins, key);
    }
  },
  actions: {
    createNewScreenPage(action: IAction, n) {
      const { commit, state } = action;
      if (state.pages.length > 5) return;
      /*
      find empty pageNumber*/
      n = state.pages.reduce((n, page) => {
        if (page && page.pageNumber < n) return n;
        if (page && page.pageNumber === n) return n + 1;
        return n;
      }, n);

      /* last page +1
      n =
        state.pages.sort((p1, p2) => p2.pageNumber - p1.pageNumber)[0]
          .pageNumber + 1;*/
      commit("addScreenPage", n);
      commit("changeShowingScreenPage", n);
    },
    removeExistScreenPage(action: IAction, pageNumber) {
      const { state, commit, dispatch } = action;
      const totalPage = state.pages.length;
      const currentScreenPageIndex = state.pages.findIndex(
        page => page.pageNumber === pageNumber
      );
      commit("removeScreenPage", pageNumber);

      if (currentScreenPageIndex < 1 && totalPage === 1) {
        dispatch("createNewScreenPage", 1);
      } else {
        const otherScreenPageNumber =
          state.pages.length === currentScreenPageIndex
            ? state.pages[currentScreenPageIndex - 1]
            : state.pages[currentScreenPageIndex];

        commit("changeShowingScreenPage", otherScreenPageNumber.pageNumber);
      }
    },
    registerService(action: IAction, service: any) {
      action.commit("addService", {
        key: service.structure.key,
        service: _omit(service, ["settings"])
      });
    },
    registerPlugin(action: IAction, plugin: any) {
      action.commit("addPlugin", {
        key: plugin.structure.key,
        plugin: _omit(plugin, ["settings"])
      });
    },
    registerDesignComponent(
      action: IAction,
      payload: {
        targetId: string;
        VNode: VNode;
      }
    ) {
      const { state, getters } = action;
      const root = getters.showingDesignPage;
      // Traverse all vnodes and add unique ids.
      modifyAllIds(
        payload.VNode,
        state.uniqueIdCounter,
        "page" + state.showingPage + "_"
      );

      const foundVnode: VNode = findVNodeById(root, payload.targetId);

      foundVnode?.children?.push(payload.VNode);
    },
    moveDesignElement(
      action: IAction,
      payload: {
        targetId: string;
        direction: "UP" | "DOWN";
      }
    ) {
      const { getters } = action;

      const root = getters.showingDesignPage;
      const path: any[] | undefined = findPathOfVNodeById(
        root,
        payload.targetId
      );
      console.log(path);
      path?.reduce((acc: VNode[], key: any, step: number) => {
        if (step === path.length - 1) {
          // if last step sawp
          const temp = acc[key];
          if (payload.direction === "UP") {
            if (key === 0) return;
            Vue.set(acc, key, acc[key - 1]);
            Vue.set(acc, key - 1, temp);
          } else if (payload.direction === "DOWN") {
            if (key + 1 >= acc.length) return;
            Vue.set(acc, key, acc[key + 1]);
            Vue.set(acc, key + 1, temp);
          }
        } else return acc[key];

        /**
         * GRID BASE
          if (step === path.length - 1) {
          // if last step sawp
          const temp = acc[key];
          let pos = payload.direction === "UP" ? -1 : 1;

          if (acc[key + pos].tag === "ion-grid") {
            Vue.set(
              acc[key + pos].children[0].children[pos > 0 ? 0 : 1].children,
              0,
              temp
            );
            delete acc[key];
          } else if (acc.tag === "ion-col") {
            alert("col");
            // inside grid
          } else Vue.set(acc, key + pos, temp);
         */
      }, root);

      // find target VNode by using targetId
      // then move the VNode upper or downer according to direction considering the grid
    },
    /**
     * Find vnode path by targetId then delete vnode using path.reduce itareting path by path
     * lastly delete vnode using Vue.delete because of reactivity
     *
     * @param action vuex actions
     * @param targetId String
     */
    deleteDesignElement(action: IAction, targetId: string) {
      const { getters } = action;
      const root = getters.showingDesignPage;
      const path: any[] | undefined = findPathOfVNodeById(root, targetId);
      path.reduce((acc, key, i) => {
        if (path.length - 1 === i) {
          // last but one
          Vue.delete(acc, key);
        } else {
          acc = acc[key];
        }
        return acc;
      }, root);
    },
    saveScreenPageCode({ commit, getters, rootState }) {
      commit("setPageCode", {
        pageNumber: getters.showingScreenPage,
        code: rootState.workspace.session.designAndCoding.coding.code
      });
    },
    syncAppJsonWithTutorialState(action) {
      const { commit, rootState } = action;
      if (rootState.workspace.session.tutorial.tutorialState.params) {
        const appJson = JSON.parse(
          rootState.workspace.session.tutorial.tutorialState.params
        );
        if (appJson) {
          if (appJson.os) commit("setScreenOs", appJson.os);
          if (appJson.pages) commit("setPages", appJson.pages);
          if (appJson.uniqueIdCounter)
            commit("setUniqueIdCounter", appJson.uniqueIdCounter);
          if (appJson.ShowingScreenPage)
            commit("changeShowingScreenPage", appJson.ShowingScreenPage);

          /*
          commit("workspace/session/coding/setCode", appJson.pages[0].code, {
            root: true
          });*/
          if (appJson.services) commit("setServices", appJson.services);
          if (appJson.plugins) commit("setPlugins", appJson.plugins);
        }
      }
    }
  }
};
