import DesignComponent from "./designComponent";
import { VNode } from "vue";

export default new DesignComponent({
  info: {
    title: "Image",
    img: require(`../../../assets/img/icons/image.svg`)
  },
  structure: {
    tag: "ion-img",
    data: {
      props: {
        designId: "image",
        configurable: "Image"
      },
      attrs: {
        src: "/img/brand/cmlogo.png",
        draggable: true
      },
      style: {
        margin: "0 auto",
        width: "100%",
        height: "100px",
        padding: "1em",
        background: "rgb(255,255,255)"
      },
      class: []
    }
  },
  settings: [
    {
      label: "Source Url",
      inputType: "text",
      get: (vnode: VNode) => vnode.data.attrs.src,
      set: (vnode: VNode, value) => (vnode.data.attrs.src = value)
    },
    {
      label: "Width",
      inputType: "text",
      get: (vnode: any) => vnode.data.style.width.replace("px", ""),
      set: (vnode: any, value) => (vnode.data.style.width = value + "px")
    },
    {
      label: "Height",
      inputType: "text",
      get: (vnode: any) => vnode.data.style.height.replace("px", ""),
      set: (vnode: any, value) => (vnode.data.style.height = value + "px")
    }
  ]
});
