export default [
  {
    text: "Default",
    value: null
  },
  {
    text: "Start",
    value: "start"
  },
  {
    text: "End",
    value: "end"
  }
];
