import DesignComponent from "./designComponent";
import { VNode } from "vue/types/umd";
import { ionColors } from "./ion-specific/index";

const ionIcon = new DesignComponent({
  info: {
    img: require(`../../../assets/img/icons/fabButton.svg`)
  },
  structure: {
    tag: "ion-icon",
    data: {
      attrs: {
        name: "add"
      },
      style: {},
      class: []
    }
  }
});

const ionFabButton = new DesignComponent({
  info: {
    title: "Floating Action Button",
    img: require(`../../../assets/img/icons/fabButton.svg`)
  },
  structure: {
    tag: "ion-fab-button",
    data: {
      attrs: {
        color: "primary"
      }
    },
    children: [ionIcon.structure]
  }
});

export default new DesignComponent({
  info: {
    title: "Floating Action Button",
    img: require(`../../../assets/img/icons/fabButton.svg`)
  },
  structure: {
    tag: "ion-fab",
    data: {
      props: {
        designId: "fabButton",
        configurable: "FabButton"
      },
      attrs: {
        draggable: false,
        vertical: "bottom",
        horizontal: "end",
        slot: "fixed",
        edge: false
      },
      style: {},
      class: []
    },
    children: [ionFabButton.structure]
  },
  settings: [
    {
      label: "Button Color",
      inputType: "select",
      options: ionColors,
      get: (vnode: VNode) => vnode.children[0].data.attrs.color,
      set: (vnode: VNode, value) => (vnode.children[0].data.attrs.color = value)
    },
    {
      label: "Horizontal Alignment",
      options: [
        { value: "center", text: "Center" },
        { value: "end", text: "End" },
        { value: "start", text: "Start" }
      ],
      inputType: "select",
      get: (vnode: VNode) => vnode.data.attrs.horizontal,
      set: (vnode: VNode, value) => (vnode.data.attrs.horizontal = value)
    },
    {
      label: "Vertical Alignment",
      options: [
        { value: "bottom", text: "Bottom" },
        { value: "center", text: "Center" },
        { value: "top", text: "Top" }
      ],
      inputType: "select",
      get: (vnode: VNode) => vnode.data.attrs.vertical,
      set: (vnode: VNode, value) => (vnode.data.attrs.vertical = value)
    },
    {
      label: "Edge",
      options: [
        { value: "true", text: "True" },
        { value: "false", text: "False" }
      ],
      inputType: "select",
      get: (vnode: VNode) => vnode.data.attrs.edge,
      set: (vnode: VNode, value) => (vnode.data.attrs.edge = value)
    }
  ]
});
