import { VNode } from "vue";
import DesignComponent from "./designComponent";
import { ionColors, ionAlign, ionSize } from "./ion-specific/index";

const ToolbarTitle = new DesignComponent({
  structure: {
    tag: "ion-title",
    data: {
      attrs: {
        color: null,
        size: "default",
        slot: "start"
      },
      domProps: {
        innerHTML: "Title Only"
      }
    }
  }
});

export default new DesignComponent({
  info: {
    title: "Toolbar",
    img: require(`../../../assets/img/icons/toolbar.svg`)
  },
  structure: {
    tag: "ion-toolbar",
    data: {
      props: {
        designId: "toolbar",
        configurable: "Toolbar"
      },
      attrs: {
        color: null,
        draggable: true
      }
    },
    children: [ToolbarTitle.structure]
  },
  settings: [
    {
      label: "Toolbar Color",
      inputType: "select",
      options: ionColors,
      /* tslint-disable */
      get: (vnode: VNode) => vnode.data.attrs.color,
      set: (vnode: VNode, value) => (vnode.data.attrs.color = value)
    },
    {
      label: "Title",
      inputType: "text",
      get: (vnode: VNode) => vnode.children[0].data.domProps.innerHTML,
      set: (vnode: VNode, value) =>
        (vnode.children[0].data.domProps.innerHTML = value)
    } /*
    {
      label: "Title Color",
      inputType: "select",
      options: ionColors,
      get: (vnode: VNode) => vnode.children[0].data.attrs.color,
      set: (vnode: VNode, value) => (vnode.children[0].data.attrs.color = value)
    },*/,
    {
      label: "Title Size",
      inputType: "select",
      options: ionSize,
      get: (vnode: VNode) => vnode.children[0].data.attrs.size,
      set: (vnode: VNode, value) => (vnode.children[0].data.attrs.size = value)
    },
    {
      label: "Title Align",
      inputType: "select",
      options: ionAlign,
      get: (vnode: VNode) => vnode.children[0].data.attrs.slot,
      set: (vnode: VNode, value) => (vnode.children[0].data.attrs.slot = value)
    }
  ]
});
