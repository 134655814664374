import { VNode } from "vue";
import DesignComponent from "./designComponent";
import { ionColors } from "./ion-specific/index";

export default new DesignComponent({
  info: {
    title: "Text box",
    img: require(`../../../assets/img/icons/text.svg`)
  },
  structure: {
    tag: "div",
    data: {
      props: {
        designId: "textbox",
        configurable: "Textbox"
      },
      attrs: {
        color: "dark",
        draggable: true
      },
      domProps: {
        innerHTML: "Text content."
      },
      style: {
        "background-color": "white",
        "font-size": "1em",
        "text-align": "center",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        margin: "0 auto",
        color: "var(--dark)"
      },
      class: ["ion-text-start"]
    }
  },
  settings: [
    {
      label: "Content",
      inputType: "text",
      get: (vnode: VNode) => vnode.data.domProps.innerHTML,
      set: (vnode: VNode, value) => (vnode.data.domProps.innerHTML = value)
    },
    {
      label: "Color",
      inputType: "select",
      options: ionColors,
      get: (vnode: VNode) =>
        vnode.data.style["color"].replace("var(--", "").replace(")", ""),
      set: (vnode: VNode, value) => {
        vnode.data.style["color"] = "var(--" + value + ")";
      }
    },
    {
      label: "Font Size",
      inputType: "select",
      options: [
        { value: "1em", text: 1 },
        { value: "1.1em", text: 2 },
        { value: "1.3em", text: 3 },
        { value: "1.4em", text: 4 },
        { value: "1.5em", text: 5 },
        { value: "1.6em", text: 6 },
        { value: "1.7em", text: 7 },
        { value: "1.8em", text: 8 },
        { value: "1.9em", text: 9 },
        { value: "2em", text: 10 }
      ],
      get: (vnode: VNode) => vnode.data.style["font-size"],
      set: (vnode: VNode, value) => (vnode.data.style["font-size"] = value)
    },
    {
      label: "Alignment",
      inputType: "select",
      options: [
        { value: "start", text: "Left" },
        { value: "center", text: "Center" },
        { value: "end", text: "Right" },
        { value: "justify", text: "Justify" },
        { value: "wrap", text: "Wrap" },
        { value: "unwrap", text: "Unwrap" }
      ],
      get: (vnode: VNode) => {
        const found = vnode.data.class.find(str => str.includes("ion-text-"));
        if (found) return found.replace("ion-text-", "");
      },
      set: (vnode: VNode, value) => {
        const list = vnode.data.class.filter(str => str.includes("ion-text-"));
        if (list.length) {
          /*
          list.forEach(element => {
            delete vnode.data.class[element];
          });*/
          // Because there is one class used.
          vnode.data.class.length = 0;
        }
        vnode.data.class.push("ion-text-" + value);
      }
    }
  ]
});
