import DesignComponent from "./designComponent";
import { VNode } from "vue";

const ionLabel = new DesignComponent({
  structure: {
    tag: "ion-label",
    data: {
      domProps: {
        innerText: "Select"
      },
      attrs: {
        draggable: true
      }
    },
    children: []
  }
});

const ionSelectOption = n =>
  new DesignComponent({
    structure: {
      tag: "ion-select-option",
      text: "Option " + n
    }
  });

const ionSelect = new DesignComponent({
  structure: {
    tag: "ion-select",
    text: "Select",
    data: {},
    children: [
      ionSelectOption(1).structure,
      ionSelectOption(2).structure,
      ionSelectOption(3).structure
    ]
  }
});

export default new DesignComponent({
  info: {
    title: "Select",
    img: require(`../../../assets/img/icons/select.svg`)
  },
  structure: {
    tag: "ion-item",
    data: {
      props: {
        designId: "select",
        configurable: "Select"
      },
      attrs: {
        draggable: true
      },
      style: {
        "font-size": "1em"
      }
    },
    children: [ionLabel.structure, ionSelect.structure]
  },
  settings: [
    {
      label: "Content",
      inputType: "text",
      get: (vnode: VNode) => vnode.children[0].data.domProps.innerText,
      set: (vnode: VNode, value) =>
        (vnode.children[0].data.domProps.innerText = value)
    },
    {
      label: "Font Size",
      inputType: "select",
      options: [
        { value: "1em", text: 1 },
        { value: "1.1em", text: 2 },
        { value: "1.3em", text: 3 },
        { value: "1.4em", text: 4 },
        { value: "1.5em", text: 5 },
        { value: "1.6em", text: 6 },
        { value: "1.7em", text: 7 },
        { value: "1.8em", text: 8 },
        { value: "1.9em", text: 9 },
        { value: "2em", text: 10 }
      ],
      get: (vnode: VNode) => vnode.data.style["font-size"],
      set: (vnode: VNode, value) => (vnode.data.style["font-size"] = value)
    }
  ]
});
