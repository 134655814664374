import { VNode } from "vue";
import DesignComponent from "./designComponent";

const ionInput = new DesignComponent({
  structure: {
    tag: "ion-input",
    data: {
      attrs: {
        value: "",
        //  placeholder: "Input Text",
        draggable: true
      },
      style: {
        overflow: "hidden",
        "text-overflow": "ellipsis",
        color: "#333",
        "font-size": "12px",
        "text-align": "left",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        margin: "0 auto",
        flex: "0 0 auto"
      },
      domProps: {
        value: ""
      },
      class: []
    }
  }
});

const ionLabel = new DesignComponent({
  structure: {
    tag: "ion-label",
    text: "abc",
    data: {
      attrs: {
        position: "floating"
      },
      domProps: {
        innerHTML: "Input Label"
      }
    }
  }
});

export default new DesignComponent({
  info: {
    title: "Labelled Input",
    img: require(`../../../assets/img/icons/input.svg`)
  },
  structure: {
    tag: "ion-item",
    data: {
      props: {
        designId: "input",
        configurable: "LabelledInput"
      },
      attrs: {
        draggable: true
      },
      style: {
        "font-size": "1em"
      },
      class: []
    },
    children: [ionLabel.structure, ionInput.structure]
  },
  settings: [
    {
      label: "Label Content",
      inputType: "text",
      get: (vnode: VNode) => vnode.children[0].data.domProps.innerHTML,
      set: (vnode: VNode, value) =>
        (vnode.children[0].data.domProps.innerHTML = value)
    },
    {
      label: "Label Postion",
      inputType: "select",
      options: [
        {
          text: "Stacked",
          value: "stacked"
        },
        {
          text: "Floating",
          value: "floating"
        },
        {
          text: "Fixed",
          value: "fixed"
        }
      ],
      get: (vnode: VNode) => vnode.children[0].data.attrs.position,
      set: (vnode: VNode, value) =>
        (vnode.children[0].data.attrs.position = value)
    },
    {
      label: "Font Size",
      inputType: "select",
      options: [
        { value: "1em", text: 1 },
        { value: "1.1em", text: 2 },
        { value: "1.3em", text: 3 },
        { value: "1.4em", text: 4 },
        { value: "1.5em", text: 5 },
        { value: "1.6em", text: 6 }
      ],
      get: (vnode: VNode) => vnode.data.style["font-size"],
      set: (vnode: VNode, value) => (vnode.data.style["font-size"] = value)
    }
  ]
});
