import DesignComponent from "./designComponent";
import { VNode } from "vue";

const ionLabel = new DesignComponent({
  structure: {
    tag: "ion-label",
    text: "List Item",
    isListItem: true
  }
});

const ionItem = new DesignComponent({
  structure: {
    tag: "ion-item",
    children: [ionLabel.structure]
  }
});

export default new DesignComponent({
  info: {
    title: "List",
    img: require(`../../../assets/img/icons/list.svg`)
  },
  structure: {
    tag: "ion-list",
    text: "List",
    data: {
      props: {
        designId: "list",
        configurable: "List"
      },
      attrs: {
        draggable: true
      }
    },
    children: [ionItem.structure, ionItem.structure]
  }
});
