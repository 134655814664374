import { VNode } from "vue";
import DesignComponent from "./designComponent";
import { ionColors, ionAlign, ionSize } from "./ion-specific/index";

const Content = new DesignComponent({
  structure: {
    tag: "ion-content",
    data: {
      props: {
        designId: "page-content"
      },
      attrs: {
        color: null,
        padding: true
      },
      style: {
        //  "--background": "white"
      }
    },
    children: []
  }
});

const Header = new DesignComponent({
  structure: {
    tag: "ion-header",
    data: {
      props: {
        designId: "page-header"
      }
    },
    children: []
  }
});

export default new DesignComponent({
  info: {
    hidden: true,
    title: "Page"
  },
  structure: {
    tag: "ion-app",
    data: {
      props: {
        designId: "page",
        configurable: "Page"
      },
      domProps: {
        class: "ios"
      },
      style: {
        border: "none",
        "overflow-x": "hidden",
        "overflow-y": "scroll hidden",
        "justify-content": "normal",
        "align-items": "stretch"
      }
    },
    children: [Header.structure, Content.structure]
  },
  settings: [
    {
      label: "Background Color",
      inputType: "select",
      options: ionColors,
      /* tslint-disable */
      get: (vnode: VNode) => vnode.children[1]?.data?.attrs?.color,
      set: (vnode: VNode, value) => (vnode.children[1].data.attrs.color = value)
    }
  ]
});
