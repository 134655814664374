import { VNode } from "vue";

const findVNodeById = (el: VNode, id) => {
  // control directly
  if (el?.data?.attrs?.id === id) return el;
  else if (el.children?.length) {
    // control for children
    const found = el.children.map(el => findVNodeById(el, id)).find(b => b);
    return found;
  } else return false;
};

export default findVNodeById;
