export default [
  {
    text: "Default",
    value: null
  },
  {
    text: "Primary",
    value: "primary"
  },
  {
    text: "Secondary",
    value: "secondary"
  },
  {
    text: "Tertiary",
    value: "tertiary"
  },
  {
    text: "Success",
    value: "success"
  },
  {
    text: "Warning",
    value: "warning"
  },
  {
    text: "Danger",
    value: "danger"
  },
  {
    text: "Light",
    value: "light"
  },
  {
    text: "Medium",
    value: "medium"
  },
  {
    text: "Dark",
    value: "dark"
  }
];
