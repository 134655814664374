import { VNode } from "vue";
import DesignComponent from "./designComponent";
import { ionColors } from "./ion-specific/index";

const ionLabel = (slot, innerHTML) =>
  new DesignComponent({
    structure: {
      tag: "ion-label",
      data: {
        attrs: {
          slot
        },
        domProps: {
          innerHTML
        }
      }
    }
  });

export default new DesignComponent({
  info: {
    title: "Range Input",
    img: require(`../../../assets/img/icons/input.svg`)
  },
  structure: {
    tag: "ion-range",
    data: {
      props: {
        designId: "range",
        configurable: "Range"
      },
      attrs: {
        draggable: false,
        color: "primary",
        min: 0,
        max: 100,
        pin: true,
        step: 1
      },
      style: {
        padding: '0 0 10px 0'
      },
      class: []
    },
    children: [ionLabel("start", 0).structure, ionLabel("end", 100).structure]
  },
  settings: [
    {
      label: "Range Color",
      inputType: "select",
      options: ionColors,
      get: (vnode: VNode) => vnode.data.attrs.color,
      set: (vnode: VNode, value) => (vnode.data.attrs.color = value)
    },
    {
      label: "Minimum",
      inputType: "number",
      get: (vnode: VNode) => vnode.data.attrs.min,
      set: (vnode: VNode, value) => {
        vnode.data.attrs.min = value;
        vnode.children[0].data.domProps.innerHTML = value;
      }
    },
    {
      label: "Maximum",
      inputType: "number",
      get: (vnode: VNode) => vnode.data.attrs.max,
      set: (vnode: VNode, value) => {
        vnode.data.attrs.max = value;
        vnode.children[1].data.domProps.innerHTML = value;
      }
    },
    {
      label: "Step",
      inputType: "number",
      get: (vnode: VNode) => vnode.data.attrs.step,
      set: (vnode: VNode, value) => (vnode.data.attrs.step = value)
    }
  ]
});
