import DesignComponent from "./designComponent";
import { VNode } from "vue";

export default new DesignComponent({
  info: {
    title: "Date time picker",
    img: require(`../../../assets/img/icons/datetimepicker.svg`)
  },
  structure: {
    tag: "ion-datetime",
    data: {
      props: {
        designId: "datetime",
        configurable: "Datetime"
      },
      attrs: {
        draggable: true,
        value: Date()
      },
      domProps: {
        displayFormat: "MMMM D, YYYY",
        min: "1699-12-19",
        max: "2099-12-19"
      },
      style: {
        "font-size": "1em"
      },
      class: []
    }
  },
  settings: [
    {
      label: "Font Size",
      inputType: "select",
      options: [
        { value: "1em", text: 1 },
        { value: "1.1em", text: 2 },
        { value: "1.3em", text: 3 },
        { value: "1.4em", text: 4 },
        { value: "1.5em", text: 5 },
        { value: "1.6em", text: 6 },
        { value: "1.7em", text: 7 },
        { value: "1.8em", text: 8 },
        { value: "1.9em", text: 9 },
        { value: "2em", text: 10 }
      ],
      get: (vnode: VNode) => vnode.data.style["font-size"],
      set: (vnode: VNode, value) => (vnode.data.style["font-size"] = value)
    }
  ]
});
