import { VNode } from "vue";

export default class DesignComponent {
  info?: {
    title?: string;
    img?: string;
    hidden?: boolean;
    disabled?: boolean;
  };

  structure: VNode = {
    isRootInsert: false,
    isComment: false
  };

  settings: any;

  constructor({
    info,
    structure,
    settings
  }: {
    info?: {
      hidden?: boolean;
      title?: string;
      img?: string;
      disabled?: boolean;
    };
    structure: any;
    settings?: any;
  }) {
    if (info) this.info = info;

    this.structure = {
      ...structure,
      isRootInsert: false,
      isComment: false
    };

    if (!this.structure.children && this.info?.title) {
      this.structure.children = [
        {
          text: this.info.title,
          isRootInsert: false,
          isComment: false
        }
      ];
    }

    if (settings) {
      this.settings = settings;
    }
  }
}
