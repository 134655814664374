import DesignComponent from "./designComponent";
import { VNode } from "vue";

const ionToggle = new DesignComponent({
  structure: {
    tag: "ion-toggle",
    data: {
      attrs: {
        slot: "end",
        color: "primary"
      }
    }
  }
});

const ionLabel = new DesignComponent({
  structure: {
    tag: "ion-label",
    text: "Label",
    data: {}
  }
});

export default new DesignComponent({
  info: {
    title: "Toggle",
    img: require(`../../../assets/img/icons/toggle.svg`)
  },
  structure: {
    tag: "ion-item",
    data: {
      props: {
        designId: "toggle",
        configurable: "Toggle"
      },
      attrs: {
        draggable: true
      },
      style: {
        color: "black",
        "font-size": "1em",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none"
      },
      class: []
    },
    children: [ionToggle.structure, ionLabel.structure]
  },
  settings: [
    {
      label: "Content",
      inputType: "text",
      get: (vnode: VNode) => vnode.children[1].text,
      set: (vnode: VNode, value) => (vnode.children[1].text = value)
    },
    {
      label: "Font Size",
      inputType: "select",
      options: [
        { value: "1em", text: 1 },
        { value: "1.1em", text: 2 },
        { value: "1.3em", text: 3 },
        { value: "1.4em", text: 4 },
        { value: "1.5em", text: 5 },
        { value: "1.6em", text: 6 },
        { value: "1.7em", text: 7 },
        { value: "1.8em", text: 8 },
        { value: "1.9em", text: 9 },
        { value: "2em", text: 10 }
      ],
      get: (vnode: VNode) => vnode.data.style["font-size"],
      set: (vnode: VNode, value) => (vnode.data.style["font-size"] = value)
    }
  ]
});
