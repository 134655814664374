import DesignComponent from "./designComponent";
import { VNode } from "vue";

const linkDiv = new DesignComponent({
  structure: {
    tag: "link-div",
    data: {
      attrs: {
        draggable: true,
        href: "#"
      },
      domProps: {
        innerHTML: "Example Link"
      },
      style: {
        color: "#3880ff",
        "text-decoration": "none"
      },
      class: []
    }
  }
});

export default new DesignComponent({
  info: {
    title: "Link",
    img: require(`../../../assets/img/icons/link.svg`)
  },
  structure: {
    tag: "div",
    data: {
      props: {
        designId: "link",
        configurable: "Link"
      },
      attrs: {
        draggable: true
      },
      style: {
        "background-color": "white",
        margin: "0 auto",
        "text-align": "center",
        "font-size": "14px",
        "font-weight": "normal",
        "font-style": "normal"
      },
      class: []
    },
    children: [linkDiv.structure]
  }
});
