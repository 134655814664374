export default [
  {
    text: "Small",
    value: "small"
  },
  {
    text: "Default",
    value: "default"
  },
  {
    text: "Large",
    value: "large"
  }
];
